import { useCallback, useEffect, useState } from 'react';
import KakaoMap from './KakaoMap';
import './styles/custom.css';
import './styles/style.scss';

export default function Location() {
  const [visible] = useState(true);

  const [markerPositions] = useState([[37.478367, 126.930652]]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let fixedMapSize = [500, 500]; // 원하는 고정 맵 크기
  const [mapSize, setMapSize] = useState(fixedMapSize);

  // 윈도우 크기가 특정 값 이하일 때 맵 크기를 조절하는 함수
  // `useCallback`을 사용하여 함수가 필요할 때만 새로 생성되도록 합니다.
  // eslint-disable-next-line no-lone-blocks

  const updateMapSizeForSmallWindow = useCallback(() => {
    // 윈도우 크기가 768픽셀 이하인지 체크합니다.
    if (window.innerWidth <= 768) {
      // 윈도우 크기에 맞춰 맵의 크기를 조절합니다.
      const newWidth = window.innerWidth - 80;
      const newHeight = window.innerHeight - 80;
      setMapSize([newWidth, newHeight]); // 조건에 따라 상태를 업데이트합니다.
    } else {
      setMapSize(fixedMapSize); // 조건에 따라 상태를 업데이트합니다.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // `fixedMapSize`를 의존성 배열에서 제거합니다.
  useEffect(() => {
    updateMapSizeForSmallWindow();

    // 윈도우 크기가 변경될 때 맵 사이즈를 업데이트합니다.
    window.addEventListener('resize', updateMapSizeForSmallWindow);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener('resize', updateMapSizeForSmallWindow);
    };
  }, [updateMapSizeForSmallWindow]); // `updateMapSizeForSmallWindow` 함수를 의존성 배열에 추가합니다.

  return (
    <div className="App">
      <div id="wrap">
        {visible && (
          <>
            <div className="container">
              <div className="heading_container">
                <h2>오시는길</h2>
              </div>
            </div>
            <KakaoMap markerPositions={markerPositions} size={mapSize} />
          </>
        )}
      </div>
    </div>
  );
}
