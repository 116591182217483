import React, { useState } from 'react';

function EmailForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('Email sent successfully');
        // 여기에 성공 메시지를 표시하는 로직을 추가하십시오.
      } else {
        console.error('Email not sent');
        // 여기에 실패 메시지를 표시하는 로직을 추가하십시오.
      }
    } catch (error) {
      console.error('There was an error sending the email', error);
      // 여기에 에러 메시지를 표시하는 로직을 추가하십시오.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <input
        type="text"
        name="message"
        className="message-box"
        placeholder="Message"
        value={formData.message}
        onChange={handleChange}
      />
      <div className="d-flex">
        <button type="submit">Send</button>
      </div>
    </form>
  );
}

export default EmailForm;
