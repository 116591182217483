import './styles/bootstrap.css';
import './styles/responsive.css';
import './styles/style.scss';
import './styles/custom.css';
import Location from './Location';
import EmailForm from './EmailForm';
import { HashLink as Link } from 'react-router-hash-link';
const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -70; // 이 값을 조정하여 스크롤 위치를 조정하세요
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

function App() {
  return (
    <div className="App">
      <div className="hero_area">
        {/* start header section */}
        <header>
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg custom_nav-container ">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                className="navbar-brand"
                smooth
                to="#"
                scroll={scrollWithOffset}
              >
                <span>
                  신림 <span id="span-5">5</span>구역
                </span>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                  <ul className="navbar-nav  ">
                    <li className="nav-item">
                      {/* 변경된 부분: <a> 대신 <Link> 사용 */}
                      <Link
                        className="nav-link"
                        smooth
                        to="#slider_section"
                        scroll={scrollWithOffset}
                      >
                        홈 <span className="sr-only">(current)</span>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      {/* 변경된 부분: <a> 대신 <Link> 사용 */}
                      <Link
                        className="nav-link"
                        smooth
                        to="#us_section"
                        scroll={scrollWithOffset}
                      >
                        전화번호
                      </Link>
                    </li>
                    <li className="nav-item">
                      {/* 변경된 부분: <a> 대신 <Link> 사용 */}
                      <Link
                        className="nav-link"
                        smooth
                        to="#heathy_section"
                        scroll={scrollWithOffset}
                      >
                        오시는길
                      </Link>
                    </li>
                    <li className="nav-item">
                      {/* 변경된 부분: <a> 대신 <Link> 사용 */}
                      <Link
                        className="nav-link"
                        smooth
                        to="#info_section"
                        scroll={scrollWithOffset}
                      >
                        커뮤니티
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
        {/* end header section */}
        {/* start slider section */}
        <section
          className=" slider_section position-relative"
          id="slider_section"
        >
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container">
                  <div className="col-lg-10 col-md-11 mx-auto">
                    <div className="detail-box">
                      <div>
                        <h3>신속</h3>
                        <h2>통합</h2>
                        <h1>청렴</h1>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse .
                        </p>
                        <div className="">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="#">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="container">
                  <div className="col-lg-10 col-md-11 mx-auto">
                    <div className="detail-box">
                      <div>
                        <h3>신속</h3>
                        <h2>통합</h2>
                        <h1>청렴</h1>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse .
                        </p>
                        <div className="">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="container">
                  <div className="col-lg-10 col-md-11 mx-auto">
                    <div className="detail-box">
                      <div>
                        <h3>신속</h3>
                        <h2>통합</h2>
                        <h1>청렴</h1>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse .
                        </p>
                        <div className="">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="container">
                  <div className="col-lg-10 col-md-11 mx-auto">
                    <div className="detail-box">
                      <div>
                        <h3>신속</h3>
                        <h2>통합</h2>
                        <h1>청렴</h1>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse .
                        </p>
                        <div className="">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="container">
                  <div className="col-lg-10 col-md-11 mx-auto">
                    <div className="detail-box">
                      <div>
                        <h3>신속</h3>
                        <h2>통합</h2>
                        <h1>청렴</h1>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse .
                        </p>
                        <div className="">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="3"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="4"
              ></li>
            </ol>
          </div>
        </section>
        {/* end slider section */}
      </div>

      {/* start about section */}
      <section className="us_section layout_padding" id="us_section">
        <div className="container">
          <div className="heading_container">
            <h2>전화번호</h2>
          </div>

          <div className="us_container">
            <div id="img-box">
              <img src="/static/images/telephone-white.png" alt="" />
              <span>02-6334-8900</span>
            </div>
            <div className="detail-box">
              <a
                href="tel:02-6334-8900"
                type="button"
                className="btn btn-dark btn-lg"
              >
                전화걸기
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* end about section */}

      {/* start trainer section */}
      <section className="heathy_section layout_padding" id="heathy_section">
        <Location />
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="detail-box">
                <h2 id="sillim-addr">
                  서울특별시 관악구 문성로38길 3 신림 5구역 준비위원회
                </h2>
                <div className="btn-box">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="https://kko.to/A59ckouVkh">길찾기</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end trainer section */}

      {/* start trainer section */}
      <section className="trainer_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>QR 코드</h2>
          </div>
          <div className="trainer_container">
            <div id="img-box">
              <img src="/static/images/image001.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* end trainer section */}

      {/* start contact section */}
      <section className="contact_section ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 px-0">
              <div className="img-box">
                <img src="/static/images/apart-img.png" alt="" />
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="form_container pr-0 pr-lg-5 mr-0 mr-lg-2">
                <div className="heading_container">
                  <h2>이메일 문의</h2>
                </div>
                <EmailForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end contact section */}

      {/* start info section */}
      <section className="info_section layout_padding2" id="info_section">
        <div className="container">
          <div className="info_items">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="https://www.youtube.com/@user-kh1kx7pt8p/featured">
              <div className="item ">
                <div className="img-box box-1">
                  <img src="" alt="" />
                </div>
                <div className="detail-box">
                  <p>유튜브</p>
                </div>
              </div>
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="https://open.kakao.com/o/gsH08bAe">
              <div className="item ">
                <div className="img-box box-2">
                  <img src="" alt="" />
                </div>
                <div className="detail-box">
                  <p>오픈 카카오톡</p>
                </div>
              </div>
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="https://cafe.naver.com/sillim5area">
              <div className="item ">
                <div className="img-box box-3">
                  <img src="" alt="" />
                </div>
                <div className="detail-box">
                  <p>네이버 카페</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      {/* end info section */}

      {/* start footer section */}
      <footer className="container-fluid footer_section">
        <p>
          &copy; 2020 All Rights Reserved. Design by
          <a href="https://html.design/">Free Html Templates</a>
        </p>
      </footer>
      {/* end footer section */}
    </div>
  );
}

export default App;
